import { createStyles } from "@mantine/styles";

const footerStyles= createStyles((theme)=>({
    footerWrapper:{
        background:`linear-gradient(105.48deg, ${theme.colors.secondary[0]} 11.56%, ${theme.colors.primary[0]} 64.71%)`,
        borderTopRightRadius:70,
        [theme.fn.smallerThan('md')]:{
            paddingBottom:70
        }
    },
    footerLogo:{
        [theme.fn.smallerThan('xxl')]:{
            width:'180px !important'
        },
        [theme.fn.smallerThan('xl')]:{
            width:'180px !important'
        },
        [theme.fn.smallerThan('md')]:{
            width:'210px !important'
        },
        [theme.fn.smallerThan('sm')]:{
            marginTop:20,

        }
    },
    logoWrapper:{
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        flexDirection:"column",
        [theme.fn.smallerThan('sm')]:{
              flex:'none'
        },
    },
    anchorText:{
        textDecoration:'none',
        color:theme.colors.text[2],
        fontFamily:'Medium',
        fontSize:17,
        display:"flex",
        alignItems:'center',
        marginTop:6,
        [theme.fn.smallerThan('md')]:{
            fontSize:19.5,
            marginBottom:20
            
        },
    },
    mailIcon:{
        marginRight:5
    },
    mailWrapper:{
        [theme.fn.smallerThan('sm')]:{
          marginTop:25
        },
    },
    allRightMobile:{
        [theme.fn.smallerThan('xxl')]:{
            display:'none'
        },
        [theme.fn.smallerThan('sm')]:{
            display:'block'
        },
    },
    allRightDesktop:{
        [theme.fn.smallerThan(891)]:{
            fontSize:10
        },
        [theme.fn.smallerThan('md')]:{
            fontSize:8
        },
        [theme.fn.smallerThan('sm')]:{
            display:'none'
        },
    },
    lookingHelp:{
        [theme.fn.smallerThan('md')]:{
            fontSize:16.5
        },
    },
    followUs:{
        [theme.fn.smallerThan('md')]:{
            fontSize:15.5
        }
    },
}));

export default footerStyles;