import {
  Box,
  Menu,
  Modal,
  Text,
  TextInput,
  Button,
  Group,
  Grid,
  Input,
  Textarea,
} from '@mantine/core';
import { useStyles } from './style';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import { useEffect, useRef, useState } from 'react';
import { dataTypes, getLinks, properties } from './properties';
import { Outlet } from 'react-router-dom';
import CrimeLogo from '../../../assets/log.svg';
import BellIcon from '../../../assets/icons/notifications.svg';
import user from '../../../assets/icons/user.svg';
import MobileLogo from '../../../assets/icons/mobilelogo.svg';
import CrossImg from '../../../assets/icons/close.svg';
import { useDisclosure } from '@mantine/hooks';
import HamLogo from '../../../assets/icons/Vector.svg';
import SeeSomethingSaySomethingPopup from './SeeSomethingSaySomethingPopup';
import {
  clearUserData,
  doRedirectIfUnAuth,
  getHeader,
  getUserData,
  getUserType,
  updateLoggedInData,
} from '../../../config/userSession';
import { baseURL } from '../../../config';
import { useQuickstartContext } from '../../../context';
import InvitePopup from './invitePopup';

const Header = ({}) => {
  const bodyRef = useRef(null);
  const { classes, theme } = useStyles(useStyles);
  const [mobileNavOpened, setMobileNavOpened] = useState(false);
  const [dropdownOpened, setDropdownOpened] = useState(false);
  const [notificationsOpened, setNotificationsOpened] = useState(false);
  const [opened, { open, close }] = useDisclosure(false);
  const [isProfile, setIsProfile] = useState(false);
  const [userProfileImage, setUserProfileImage] = useState('');
  const [userProfileImageKey, setUserProfileImageKey] = useState();
  const { state, dispatch, profilePictureUrl } = useQuickstartContext();
  const userDetails = getUserData();

  async function fetchUserDetails() {
    const response = await fetch(`${baseURL}/account/profile`, {
      method: 'GET',
      headers: getHeader(),
    });
    if (response.ok) {
      let { user, accessLevels } = await response.json();
      const userType = user.userType.replace(/_/g, ' ');

      updateLoggedInData({ userType, ...userDetails });
    } else {
      doRedirectIfUnAuth(response.status);
    }
  }

  useEffect(() => {
    fetchUserDetails();
  }, []);

  const navigate = useNavigate();

  const userType = getUserType();

  const openMobileNavHandler = (boolean) => {
    setMobileNavOpened(boolean);
  };
  const doLogout = () => {
    clearUserData();
    navigate('/login');
    window.location.reload();
  };

  const searchProfile = () => {
    if (
      userDetails.imageUrlKey !== null &&
      userDetails.imageUrlKey !== undefined &&
      userDetails.imageUrlKey !== ''
    ) {
      setIsProfile(true);
    } else {
      setIsProfile(false);
    }
  };

  useEffect(() => {
    searchProfile();
  }, [userDetails]);

  useEffect(() => {
    setUserProfileImageKey(userDetails.imageUrlKey);
  }, [userDetails.imageUrlKey]);

  useEffect(() => {
    // This effect will run whenever profilePictureUrl changes
    console.log('Profile picture updated:', profilePictureUrl);
  }, [profilePictureUrl]); // Dependency array includes profilePictureUrl

  async function fetchProfileImage() {
    const response = await fetch(
      `${baseURL}/account/profile-image/${userProfileImageKey}`,
      {
        method: 'GET',
        headers: getHeader(),
      }
    );
    if (response.ok) {
      const res = await response.json();
      setUserProfileImage(res.imageUrl);
    } else {
      // setLoading(false);
      doRedirectIfUnAuth(response.status);
    }
  }

  useEffect(() => {
    if (userProfileImageKey) fetchProfileImage();
  }, [userDetails, userProfileImageKey]);

  const { titles } = properties;

  //for mobile navbar when opened
  document.querySelector('body').style.overflowY = mobileNavOpened
    ? 'hidden'
    : 'scroll';

  const userData = getUserData();
  const accessLevels = [...new Set(userData.accessLevels.flat())];

  return (
    <>
      <SeeSomethingSaySomethingPopup opened={opened} close={close} />

      <Box className={classes.wrapperContainer}>
        <Box className={classes.wrapper}>
          <Box className={classes.mobDiv}>
            <img
              onClick={() => openMobileNavHandler(true)}
              className={`${classes.hamLogo} ${classes.mobileNotificationAndHamburger}`}
              src={HamLogo}
              alt='hamLogo'
            />
            <img className={classes.logo} src={CrimeLogo} alt='logo' />
          </Box>

          <Box className={classes.linksAndUser}>
            <Box className={classes.links}>
              {dataTypes
                .filter((element) => accessLevels.includes(element.title))
                .map((item, index) => {
                  return (
                    <NavLink
                      className={({ isActive, isPending }) =>
                        isActive ? classes.activeLink : classes.link
                      }
                      to={item.to}
                    >
                      {item.title}
                    </NavLink>
                  );
                })}

              <NavLink
                className={({ isActive, isPending }) =>
                  isActive ? classes.activeLink : classes.link
                }
                to={'/contact-us'}
              >
                Contact Us
              </NavLink>
            </Box>
            {userType === 'User' || userType === 'Group_Manager' ? null : (
              <InvitePopup />
            )}

            <Box className={classes.notificationUserDiv}>
              {' '}
              <img src={BellIcon} className='' alt='notifications' />
              <Box className={classes.verticalBar}></Box>
              <Menu>
                <Menu.Target>
                  <Box className={classes.menuTarget}>
                    {!isProfile ? (
                      <span className={classes.demoProfile}>
                        {userDetails.firstName.charAt(0).toUpperCase() +
                          userDetails.lastName.charAt(0).toUpperCase()}
                      </span>
                    ) : (
                      <span className={classes.demoProfile}>
                        <img
                          className={classes.demoProfile}
                          src={userProfileImage}
                          alt='user'
                        />
                      </span>
                    )}
                    <BiChevronDown />
                    <Menu.Dropdown className={classes.dropdown}>
                      <Menu.Item
                        onClick={() => navigate('/profile')}
                        className={classes.menuItem}
                      >
                        {titles.myProfile}
                      </Menu.Item>
                      {userType !== 'User' && (
                        <>
                          <Menu.Item
                            onClick={() => navigate('/users')}
                            className={classes.menuItem}
                          >
                            {titles.userList}
                          </Menu.Item>
                          {userType !== 'Group_Manager' && (
                            <Menu.Item
                              onClick={() => navigate('/groups')}
                              className={classes.menuItem}
                            >
                              {titles.groups}
                            </Menu.Item>
                          )}
                        </>
                      )}

                      <Menu.Item
                        onClick={doLogout}
                        className={classes.menuItem}
                      >
                        {titles.logout}
                      </Menu.Item>
                    </Menu.Dropdown>
                  </Box>
                </Menu.Target>
              </Menu>
            </Box>

            <Grid
              ojustify='space-around'
              align='center'
              className={classes.seeSomethingButton}
              onClick={() => open()}
            >
              <Grid.Col span={2} offset={1}>
                <img
                  className={classes.seeSomethingIcon}
                  src={MobileLogo}
                  alt='hamburger'
                />
              </Grid.Col>
              <Grid.Col span={9}>
                <Text
                  className={classes.textSaysomething}
                  ta='center'
                  c='#FFFFFF'
                  ff='Semibold'
                >
                  {properties.seeSome}
                </Text>
                <Text
                  className={classes.textSaysomething}
                  ta='center'
                  c='#FFFFFF'
                  ff='Semibold'
                >
                  {properties.saySome}
                </Text>
              </Grid.Col>
            </Grid>
          </Box>
          {/* for mobile */}
          <Box className={classes.mobileNotificationAndHamburger}>
            <img
              className={classes.mobileNotification}
              src={BellIcon}
              alt='hamburger'
            />
            <Box className={classes.hamburgerContainer}>
              <img
                className={classes.hamburger}
                src={MobileLogo}
                alt='hamburger'
                onClick={open}
              />
            </Box>
          </Box>
          <Box
            className={`${classes.openedMobileNav} ${
              mobileNavOpened && classes.openTheNav
            }`}
          >
            <img
              onClick={() => {
                openMobileNavHandler(false);
                setDropdownOpened(false);
              }}
              className={classes.crossIcon}
              src={CrossImg}
              alt='notifications'
            />

            <Box
              className={`${classes.dropdownContainer} ${
                dropdownOpened && classes.dropdownContainerOpened
              }`}
            >
              <Box
                onClick={() => setDropdownOpened(!dropdownOpened)}
                className={`${classes.menuTargetMobile}`}
              >
                <Box className={classes.imageAndUsername}>
                  <img className={classes.demoUser} src={user} alt={'user'} />
                  <p className={classes.userName}>John Michael Deo</p>
                </Box>
                {dropdownOpened ? (
                  <BiChevronUp className={classes.downArrow} />
                ) : (
                  <BiChevronDown className={classes.downArrow} />
                )}
              </Box>
              <Box className={classes.menuItems}>
                <Box className={classes.menuItem}>{titles.myProfile}</Box>
                <Box onClick={doLogout} className={classes.menuItem}>
                  {titles.logout}
                </Box>
              </Box>
            </Box>
            <Box className={classes.mobileLinks}>
              {dataTypes.map((item, index) => {
                return (
                  <NavLink
                    onClick={() => openMobileNavHandler(false)}
                    className={({ isActive, isPending }) =>
                      isActive ? classes.activeLink : classes.link
                    }
                    to={item.to}
                  >
                    {item.title}
                  </NavLink>
                );
              })}
            </Box>
          </Box>
        </Box>
      </Box>

      <Outlet />
    </>
  );
};

export default Header;
