export const properties = {
  modalTitle: 'See Something, Say Something',
  crimeTitle: 'Crime Title',
  dropdownPlaceholder: 'Please Select',
  crimeCategory: 'Crime Category',
  uploadPicture: 'Upload Picture',
  uploadText: 'Add or drag & drop your files here',
  crimeDescription: 'Crime Description',
  crimeAddress: 'Crime Address',
  cancelBtn: 'Cancel',
  submitBtn: 'Submit',
  categoryData: [
    'ALL',
    'THEFT/OTHER',
    'THEFT F/AUTO',
    'MOTOR VEHICLE THEFT',
    'ROBBERY',
    'BURGLARY',
    'ASSAULT W/DANGEROUS WEAPON',
    'SEX ABUSE',
    'HOMICIDE',
    'ARSON',
  ],
  titles: {
    fileAdded: 'File Added successfully',
    fileRejected:
      'The file size should be under 5MB and it should be either an image or a PDF',
  },
};
