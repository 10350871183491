import React, { createContext, useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getUserData } from './config/userSession';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const location = useLocation()
  const lowercasePathname = location.pathname.toLowerCase();
  const normalizedPathname = lowercasePathname.startsWith("/") 
  ? lowercasePathname.substring(1).replace(/-/g, ' ') 
  : lowercasePathname.replace(/-/g, ' ');
  const login = (userData) => {
    setUser(userData);
  };

  const logout = () => {
    setUser(null);
  };

  const hasAccess = () => {
    const userData = getUserData();
    const accessLevels = [...new Set(userData.accessLevels.flat())];
    const lowerAccessLevels = accessLevels.map(level => level.toLowerCase());
    return lowerAccessLevels.includes(normalizedPathname);
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, hasAccess }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
