import { useDisclosure } from '@mantine/hooks';
import React, { useState } from 'react';

import {
  Box,
  Button,
  Group,
  Input,
  Loader,
  Modal,
  Textarea,
} from '@mantine/core';
import { hasLength, useForm } from '@mantine/form';
import cross from '../../../assets/icons/cross.svg';
import { baseURL } from '../../../config';
import {
  doRedirectIfUnAuth,
  getHeader,
  getUserType,
} from '../../../config/userSession';
import { properties } from './properties';
import { useStyles } from './style';
import { showNotification } from '../../../utils/notification';

const InvitePopup = (prop) => {
  const userType = getUserType();
  const { classes, theme } = useStyles(useStyles);
  const [isLoading, setIsLoading] = useState(false);
  console.log({ userType });
  const [opened, { close, open }] = useDisclosure(false);
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const form = useForm({
    initialValues: {
      ToEmail: '',
      Message: '',
    },
    validate: {
      ToEmail: (value) =>
        value == ''
          ? 'Please provide the email Id'
          : emailPattern.test(value)
          ? null
          : 'Invalid email',
      //Message: (value) => (!value ? 'Message Required' : null),
      Message: hasLength(
        { min: 2, max: 255 },
        'Message must be 2-255 characters long.'
      ),
    },
  });

  const handleApiResponse = async (response, form, theme, close) => {
    const apiResponse = await response.text();
    const dataResponse = JSON.parse(apiResponse);

    if (dataResponse.error) {
      showNotification('failure', dataResponse.msg, '', theme);
    } else {
      showNotification('success', 'Invitation Sent Successfully', '', theme);
      form.reset();
      close(); // close the popup
    }
  };

  const handleError = (error, theme) => {
    console.log(error);
    showNotification('failure', error.message, '', theme);
  };

  const handleSubmit = async (values) => {
    if (form.isValid()) {
      setIsLoading(true);

      try {
        const response = await fetch(`${baseURL}/account/invite`, {
          method: 'POST',
          headers: getHeader(),
          body: JSON.stringify(form.values),
        });

        setIsLoading(false);

        if (response.ok) {
          await handleApiResponse(response, form, theme, close);
        } else {
          doRedirectIfUnAuth(response.status);
          const errorResponse = await response.text();
          console.log('error is', errorResponse);
          showNotification('failure', 'Some Error occurred', '', theme);
        }
      } catch (error) {
        setIsLoading(false);
        handleError(error, theme);
      }
    }
  };

  return (
    <>
      <Modal
        withCloseButton={false}
        opened={opened}
        onClose={close}
        size='lg'
        radius='lg'
        title='Invite Member'
        centered={true}
        styles={{
          content: {
            padding: '30px',
            boxShadow: '0px 20px 30px rgba(29, 94, 109, 0.2)',
            borderRadius: '24px',
            width: 980,
          },
          title: classes.title,
        }}
      >
        <Box onClick={close}>
          <img
            className={classes.crossIconModal}
            src={cross}
            alt='notifications'
          />
        </Box>
        <form onSubmit={form.onSubmit(handleSubmit)} name='invite-popup'>
          <Input.Wrapper
            label='Email'
            mx='auto'
            mb='xl'
            padding={0}
            styles={{
              label: classes.label,
            }}
          >
            <Input
              sx={{ background: 'white' }}
              mask=''
              className={classes.textInput}
              {...form.getInputProps('ToEmail')}
              placeholder='example@email.com'
            />
            <div className={classes.errorWrapper}>
              {form?.errors?.ToEmail || ''}
            </div>
          </Input.Wrapper>
          <Input.Wrapper
            label='Message'
            mx='auto'
            padding={0}
            styles={{
              label: classes.label,
            }}
          >
            <Textarea
              mask=''
              className={classes.textInput}
              {...form.getInputProps('Message')}
              placeholder={properties.titles.textAreaDefaultMsg}
              minRows={5}
            />
          </Input.Wrapper>
          <div className={classes.footerWrapper}>
            <Button
              mt='xl'
              radius='xl'
              ta='right'
              size='lg'
              variant='filled'
              className={classes.cancelButton}
              onClick={close}
            >
              Cancel
            </Button>
            <Button
              type='submit'
              mt='xl'
              radius='xl'
              ta='right'
              size='lg'
              variant='filled'
              className={classes.invitebutton}
              disabled={isLoading}
            >
              {isLoading ? (
                <Loader
                  color='primary.0'
                  size='md'
                  variant='bars'
                  className={classes.loader}
                />
              ) : (
                'Invite'
              )}
            </Button>
          </div>
        </form>
      </Modal>

      <Group position='center'>
        <Button
          type='button'
          mt='sm'
          radius='xl'
          ta='right'
          size='lg'
          variant='filled'
          className={classes.invitebutton}
          onClick={open}
        >
          {userType === properties.userType.superAdmin
            ? 'Invite Admin'
            : 'Invite'}
        </Button>
      </Group>
    </>
  );
};

export default InvitePopup;
