import { Anchor, Box, Container, Grid, Image, Text } from "@mantine/core";
import React from "react";
import {
  allRightReserved,
  emailAddress,
  followUs,
  lookingHelp,
} from "./properties";
import footerStyles from "./style";
import { IoMdMail } from "react-icons/io";
import LogoIcon from "../../../assets/Logo.svg";
import { FaFacebookF, FaLinkedinIn, FaTwitter } from "react-icons/fa";
import { GrInstagram } from "react-icons/gr";

const Footer = () => {
  const { classes, theme } = footerStyles(footerStyles);
  return (
    <Container fluid className={classes.footerWrapper}  pt={30} pb={30}>
      <Grid align="center">
        <Grid.Col
          lg={3}
          md={3}
          sm={3}
          offsetLg={1}
          offsetMd={1}
          orderXs={2}
          orderLg={1}
          orderMd={1}
          orderXl={1}
          orderXxl={1}
          orderSm={1}
          xs={10}
          offsetXs={1}
          className={classes.mailWrapper}
        >
          <Text className={classes.lookingHelp} c={theme.colors.quaternary} fz={12} ff="Regular">
            {lookingHelp}
          </Text>
          <Anchor className={classes.anchorText} href="#">
            <IoMdMail className={classes.mailIcon} />
            {emailAddress}
          </Anchor>
        </Grid.Col>
        <Grid.Col
          className={classes.logoWrapper}
          lg={4}
          md={3}
          sm={3}
          offsetLg={0}
          orderXs={1}
          xs={10}
          offsetXs={1}
          orderLg={2}
          orderMd={2}
          orderXl={2}
          orderXxl={1}
          orderSm={2}
        >
          <Image
            className={classes.footerLogo}
            src={LogoIcon}
            alt="logo-icon"
          />
          <Text
            c={theme.colors.text[2]}
            fz={12}
            ff="Light"
            mt={10}
            className={classes.allRightDesktop}
          >
            {allRightReserved}
          </Text>
        </Grid.Col>
        <Grid.Col lg={1.5} md={2} sm={3} offsetLg={1.5} offsetMd={1.5} orderXs={3} xs={10} offsetXs={1}>
          <Text c={theme.colors.quaternary} fz={12} mb={5} ff="Regular" className={classes.followUs} >
            {followUs}
          </Text>
          <Grid>
            <Grid.Col lg={2} xs={1.5} md={2} sm={2}>
              <Anchor c={theme.colors.text[2]} href="#">
                <FaFacebookF size={25} />
              </Anchor>
            </Grid.Col>
            <Grid.Col lg={2} offsetLg={1} xs={1.5} offsetXs={0.45} sm={2}  md={1.8} >
              <Anchor c={theme.colors.text[2]} href="#">
                <GrInstagram size={25} />
              </Anchor>
            </Grid.Col>
            <Grid.Col lg={2} offsetLg={1} xs={1.5} offsetXs={0.45} sm={2}  md={1.8}>
              <Anchor  c={theme.colors.text[2]} href="#">
                <FaLinkedinIn size={25} />
              </Anchor>
            </Grid.Col>
            <Grid.Col lg={2} offsetLg={1} xs={1.5} offsetXs={0.45} sm={2}  md={1.8}>
              <Anchor  c={theme.colors.text[2]} href="#">
                <FaTwitter size={25} />
              </Anchor>
            </Grid.Col>
            <Grid.Col mt={15} xs={10} offsetXs={0}>
              <Text
                c={theme.colors.text[2]}
                fz={13}
                ff="Regular"
                className={classes.allRightMobile}
              >
                {allRightReserved}
              </Text>
            </Grid.Col>
          </Grid>
        </Grid.Col>
      </Grid>
    </Container>
  );
};

export default Footer;
