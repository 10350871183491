import React from 'react';
import { Route, Navigate, useLocation, Outlet } from 'react-router-dom';
import { useAuth } from './authContext';

const ProtectedRoute = ({ element: Element, ...rest }) => {
  const { user, hasAccess } = useAuth();
  const location = useLocation();
  if (!location.pathname.includes('profile')) {
    if (!hasAccess()) {
      return <Navigate to='/' />;
    }
  }
  return <Outlet />;
};

export default ProtectedRoute;
