import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  content: {
    'padding': '35px',
    'display': 'flex',
    'flexDirection': 'column',
    'height': '916px',
    'minWidth': 1036,
    'borderRadius': '24px',
    'overflowY': 'auto',
    'maxHeight': '80vh',
    'zIndex': 999999,
    '.mantine-Modal-header': {
      display: 'flex',
    },
    [theme.fn.smallerThan('xl')]: {
      height: '95%',
      minWidth: 0,
    },

    [theme.fn.smallerThan('md')]: {
      minWidth: 0,
    },
    [theme.fn.smallerThan('sm')]: {
      height: '90%',
      padding: 20,
      minWidth: 0,
      width: '90%',
    },
  },
  modalTitle: {
    fontFamily: `Medium , ${theme.fontFamily}`,
    fontSize: 30,
    fontWeight: 400,
    color: theme.colors.secondary[0],
    [theme.fn.smallerThan('xl')]: {
      fontSize: 25,
    },
    [theme.fn.smallerThan('md')]: {},
    [theme.fn.smallerThan('sm')]: {
      fontSize: 23,
      display: 'flex',
      width: '70%',
      lineHeight: '34.5px',
    },
  },

  gridContainer: {
    marginTop: 50,
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: 30,
    [theme.fn.smallerThan('xl')]: {
      marginTop: 30,
    },
    [theme.fn.smallerThan('md')]: {
      marginTop: 20,
    },
    [theme.fn.smallerThan('sm')]: {
      gridTemplateColumns: 'repeat(1, 1fr)',

      marginTop: 10,
    },
  },
  gridItemFullWidth: {
    marginTop: 25,
    padding: '10px',
    gridColumn: 'span 2',
  },
  label: {
    fontFamily: `Regular , ${theme.fontFamily}`,
    fontWeight: 400,
    fontSize: 16,
    color: theme.colors.text[3],
    letterSpacing: 0.14,
  },
  textInput: {
    input: {
      'background': theme.colors.text[2],
      'borderRadius': 12,
      'padding': 10,
      'height': 55,
      'fontFamily': `Semibold , ${theme.fontFamily}`,
      'fontSize': 19,
      'outline': 'none',
      'transition': '.5s',
      'color': theme.colors.secondary[0],
      'fontWeight': 700,
      '&::placeholder': {
        color: `${theme.colors.text[3]}`,
        fontFamily: `Regular , ${theme.fontFamily}`,
        fontSize: 16,
        opacity: '50%',
        [theme.fn.smallerThan('xl')]: {},
      },
      ':focus': {
        color: theme.colors.text[4],
        borderColor: theme.colors.outline[3],
      },
    },
    textarea: {
      'background': theme.colors.text[2],
      'borderRadius': 12,
      'padding': 10,
      'fontFamily': `Bold , ${theme.fontFamily}`,
      'fontsize': 12,
      'height': 260,
      'outline': 'none',
      'transition': '.5s',
      'color': theme.colors.secondary[0],
      'fontSize': 19,
      '&::placeholder': {
        color: `${theme.colors.text[3]}`,
        fontFamily: `Regular , ${theme.fontFamily}`,
        fontSize: 16,
        opacity: '50%',
        [theme.fn.smallerThan('xl')]: {},
      },
      ':focus': {
        borderColor: theme.colors.outline[3],
      },
      [theme.fn.smallerThan('xl')]: {},
      [theme.fn.smallerThan('md')]: {
        height: 150,
      },
      [theme.fn.smallerThan('sm')]: {
        height: 200,
      },
    },
  },
  uploadPicture: {
    borderRadius: 16,
    height: 260,
    width: '100%',
    backgroundColor: theme.colors.background[6],
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 12,
    [theme.fn.smallerThan('xl')]: {},
    [theme.fn.smallerThan('md')]: {
      height: 150,
    },
    [theme.fn.smallerThan('sm')]: {
      border: `dashed ${theme.colors.background[0]}`,
      height: 200,
    },
  },
  uploadText: {
    fontFamily: `Regular , ${theme.fontFamily}`,
    fontWeight: 400,
    color: theme.colors.text[3],
    fontSize: 14,
    marginTop: 10,
  },
  dropZoneText: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    fontFamily: `Regular , ${theme.fontFamily}`,
    fontWeight: 400,
    color: theme.colors.text[3],
    fontSize: 14,
  },
  uploadContainer: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    backgroundColor: theme.colors.background[6],
  },
  locationIcon: {
    height: 24,
    width: 24,
  },
  actionBtns: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 50,
    columnGap: 30,
    [theme.fn.smallerThan('xl')]: {},
    [theme.fn.smallerThan('md')]: {},
    [theme.fn.smallerThan('sm')]: {
      width: '100%',
      columnGap: 30,
      display: 'flex',
      flexDirection: 'column-reverse',
      justifyContent: 'space-between',
    },
    [theme.fn.smallerThan(850)]: {},
  },
  cancel: {
    'fontFamily': `Bold , ${theme.fontFamily}`,
    'fontSize': 16,
    'fontWeight': 600,
    'color': theme.colors.secondary[0],
    'textDecoration': 'none',
    ':hover': {
      cursor: 'pointer',
    },
    [theme.fn.smallerThan(650)]: {
      marginTop: 20,
    },
  },
  submitBtn: {
    width: 200,
    height: 56,
    letterSpacing: 0.15,
    fontFamily: `Regular , ${theme.fontFamily}`,
    backgroundColor: theme.colors.quaternary[0],
    color: theme.colors.text[2],
    borderRadius: 12,
    fontSize: 16,
    fontWeight: 600,
    marginLeft: 40,
    [theme.fn.smallerThan('xl')]: {
      height: 40,
    },
    [theme.fn.smallerThan('md')]: {},
    [theme.fn.smallerThan('sm')]: {
      marginLeft: 0,
    },
  },
  inputWrapper: {
    [theme.fn.smallerThan('xl')]: {
      marginBottom: 15,
    },
    input: {
      'borderRadius': 12,
      'fontFamily': `Regular , ${theme.fontFamily}`,
      'fontsize': 12,
      'height': 55,
      'border': `2px solid ${theme.colors.background[0]}`,

      'fontWeight': 400,
      'opacity': '50%',
      'color': '#000000',
      [theme.fn.smallerThan('xl')]: {
        width: '100%',
      },
      '&:focus': {
        border: `2px solid ${theme.colors.outline[3]}`,
        boxshadow: `0px 15px 25px 0px #0675DE14`,
        color: `${theme.colors.secondary[0]}`,
        fontFamily: 'Bold',
        boxShadow: `0px 15px 25px 0px #0675DE14`,
      },
      '&::placeholder': {
        color: `${theme.colors.text[3]}`,
        fontFamily: `Regular , ${theme.fontFamily}`,
        fontSize: 16,
        opacity: '50%',
        [theme.fn.smallerThan('xl')]: {},
      },
    },
  },
}));
