import React from 'react';
import { Route, Navigate, useLocation, Outlet } from 'react-router-dom';
import { useAuth } from './authContext';
import { getUserData } from './config/userSession';

const ProtectedGroupRoute = ({ element: Element, ...rest }) => {
  const userData = getUserData()
  
  if (userData.userType.includes('User') || userData.userType.includes('Group_Manager') ) {
      return <Navigate to='/' />;
  }
  return <Outlet />;
};

export default ProtectedGroupRoute;
