import React, { lazy } from 'react';
import { Routes, Route, Redirect } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import Header from './pages/common/header';
import Footer from './pages/common/footer/Footer';
import ProtectedRoute from './privateRoutes';
import { AuthProvider } from './authContext';
import ProtectedAdminRoute from './protectedAdminRoute';
import ProtectedGroupRoute from './protectedGroupRoute';
const Login = lazy(()=>import ('./pages/common/login/Login'));
const  ForecastingMainPage=  lazy(()=> import('./pages/public/forecasting/mainPage'));
const  ContactUs=  lazy(()=> import('./pages/public/contactus/mainPage'));
const  NPConsMainPage=  lazy(()=> import('./pages/public/npCons/mainPage'));
const  LawEnforcement=  lazy(()=> import('./pages/public/LawEnforcement/mainPage'));
const Dashboard = lazy(()=>import('./pages/public/dashboard'));
const HomePage = lazy(() => import('./pages/common/HomePage'));
const Home = lazy(() => import('./pages/common/Home'));
const Signup = lazy(()=> import('./pages/common/signup/Signup'));
const ForgotPassword = lazy(()=>import ('./pages/common/forgotPassword/ForgotPassword'));
const PastCrimes = lazy(()=> import('./pages/public/PastCrime/mainPage'));
const UserList = lazy(()=> import('./pages/users'));
const UserListEdit = lazy(()=> import('./pages/userListEdit'));
const GroupList = lazy(()=> import('./pages/groups'));
const CreateGroup = lazy(()=> import('./pages/createGroup'));
const EditGroup = lazy(()=> import('./pages/groupEdit'));
const ProfilePage = lazy(()=> import('./pages/common/ProfilePage'));

const PageNotFound = lazy(()=>import ('./pages/common/PageNotFound'));

const BrowserRoutes = ({ type, setType, showNotification }) => {


  const pageVariants = {
    initial: {
      opacity: 0,
      scale: 0.99,
    },
    in: {
      opacity: 1,
      scale: 1,
    },
    out: {
      opacity: 0,
      scale: 1.01,
    },
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.4,
  };

  return (
    <AnimatePresence>
      <AuthProvider>
      <Routes>

        
        <Route path='/' element={<Home />} />
        <Route path='/login' element= { <Login/>} />
        <Route path='/dashboard' element={ <><Header/> <Dashboard/></>}/>
        <Route path='/signup' element={<><Signup/></>} />
        <Route path='/forgot-password' element={<ForgotPassword/>} />
        <Route path='/contact-us' element={<><Header/><ContactUs/> <Footer/></>} />
        <Route element={<ProtectedAdminRoute />}>
          <Route path='/users' element={<><Header/><UserList /><Footer/></>} />
          <Route path='/users/edit/:id' element={<><Header/><UserListEdit /><Footer/></>} />
        </Route>
        <Route element={<ProtectedGroupRoute />}>
          <Route path='/groups' element={<><Header/><GroupList /><Footer/></>} />
          <Route path='/add-group' element={<><Header/><CreateGroup /><Footer/></>} />
          <Route path='/group/edit/:id' element={<><Header/><EditGroup /><Footer/></>} />
        </Route>

        <Route element={<ProtectedRoute />}>
          <Route path='/forecasting' element={<><Header/><ForecastingMainPage/><Footer/></>} />
          <Route path='/npcon' element={<><Header/><NPConsMainPage/>  <Footer/></>} />
          <Route path='/past-crime' element={<><Header/><PastCrimes/>  <Footer/></>} />
          <Route path='/law-enforcement' element={<><Header/><LawEnforcement/> <Footer/></>} />
         
          <Route path='/profile' element={<><Header/><ProfilePage /><Footer/></>} />
          <Route path="*" element={<><Header/><PageNotFound/></>} />
        </Route>
      </Routes>
      </AuthProvider>
    </AnimatePresence>
  );
};

export default BrowserRoutes;
