export const properties = {
  titles: {
    myProfile: 'My Profile',
    userList: 'User',
    groups: 'Groups',
    logout: 'Log out',
  },
  seeSome: 'See Something',
  saySome: 'Say Something',
  userType: {
    admin: 'Admin',
    groupOwner: 'Group Owner',
    groupManager: 'Group Manager',
  },
};

export const dataTypes = [
  { title: 'Forecasting', to: '/forecasting  ' },
  { title: 'NPCon', to: '/npcon' },
  { title: 'Past Crime', to: '/past-crime' },
  { title: 'Law Enforcement', to: '/law-enforcement' },
];
