import { notifications } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';

export const showNotification = (notificationType, message, title, theme) => {
  let colorCode = notificationType == 'success' ? 'secondary.0' : 'red';
  notifications.show({
    id: 'invite-notification',
    withCloseButton: true,
    autoClose: 5000,
    title: title,
    message: message,
    color: colorCode,
    icon:
      notificationType == 'success' ? <IconCheck size='1.1rem' /> : <IconX />,
    style: { backgroundColor: theme.colors.background[0] },
    loading: false,
  });
};
