import { Box, createStyles, Loader, MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import { QuickstartProvider } from './context';
import BrowserRoutes from './routes';

const themeConfiguration = () => {
  return {
    colorScheme: 'light',
    colors: {
      primary: ['#1D2643'],
      secondary: ['#3B4771'],
      tertiary: ['#06B9B9'],
      quaternary: ['#00E1E1'],
      error: ['#ED2424'],
      green: ['#60C13E'],
      yellow: ['#EDB237'],
      red: ['#F04040'],
      orange: ['#FA8128'],
      background: [
        '#E8E9EC',
        '#F5F6F8',
        '#DAF4F4',
        '#E5FCFC',
        '#E6F3F3',
        '#FFE9E9',
        '#F7F9FA',
        '#000000',
        '#FFFFFF',
        '#515974',
        '#EAFFD6',
      ],
      outline: ['#A5A8B4', '#B1B5C6', '#9BE3E3', '#99F3F3', '#FCD9D9'],
      text: ['#2A2B2E', '#616161', '#FFFFFF', '#797D83', '#1D5E6D', '#FC5858'],
      shadow: ['#00000008', '#00E1E133'],
    },
    breakpoints: {
      xs: '23em',
      sm: '36em',
      md: '48em',
      lg: '85em',
      xl: '100em',
      xxl: '130em ',
    },

    shadows: {
      md: '1px 1px 3px rgba(0, 0, 0, .25)',
      xl: '5px 5px 3px rgba(0, 0, 0, .25)',
    },
  };
};
const useStyles = createStyles((theme) => ({
  loaderWrapper: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    alignContent: 'center',
    width: '100vw',
    height: '100vh',
    zIndex: '99999',
  },
}));
function App() {
  const { classes } = useStyles(useStyles);
  return (
    <Suspense
      fallback={
        <Box className={classes.loaderWrapper}>
          {' '}
          <Loader
            className={classes.loader}
            variant='oval'
            color='#1D2643'
            size='4rem'
          />{' '}
        </Box>
      }
    >
      <QuickstartProvider>
        <MantineProvider
          withGlobalStyles
          withNormalizeCSS
          theme={themeConfiguration()}
        >
          <Notifications />
          <BrowserRouter>
            <BrowserRoutes />
          </BrowserRouter>
        </MantineProvider>
      </QuickstartProvider>
    </Suspense>
  );
}

export default App;
