import {
  Box,
  Button,
  Modal,
  Select,
  TextInput,
  Textarea,
  Group,
  rem,
  Text,
} from '@mantine/core';
import { useStyles } from './style';
import addIcon from '../../../../assets/icons/add.svg';
import { BiLocationPlus } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { IconChevronDown, IconUpload, IconX } from '@tabler/icons-react';
import { properties } from './properties';
import { Dropzone, DropzoneProps, MIME_TYPES } from '@mantine/dropzone';
import { useState } from 'react';
import { AiOutlineArrowRight, AiOutlinePlus } from 'react-icons/ai';

function SeeSomethingSaySomethingPopup({ opened, close }) {
  const { classes, theme } = useStyles(useStyles);
  const {
    modalTitle,
    crimeTitle,
    dropdownPlaceholder,
    crimeCategory,
    categoryData,
    uploadPicture,
    uploadText,
    crimeDescription,
    crimeAddress,
    cancelBtn,
    submitBtn,
    titles,
  } = properties;

  const [tempUploadedFiles, setTempUploadedFiles] = useState(null);
  const [isSelected, setIsSelected] = useState(false);
  const [inputError, setInputError] = useState({});

  const [fileRejected, isFileRejected] = useState(false);

  const addFile = (files) => {
    setIsSelected(true);
  };

  return (
    <Modal
      withCloseButton={true}
      opened={opened}
      onClose={close}
      size='lg'
      title={modalTitle}
      centered={true}
      styles={{
        content: classes.content,
        title: classes.modalTitle,
        zIndex: 999999,
      }}
    >
      <Box className={classes.gridContainer}>
        <Box className={classes.gridItem}>
          <Box className={classes.inputContainer}>
            <label className={classes.label}>{crimeTitle}</label>
            <TextInput className={classes.textInput} placeholder={crimeTitle} />
          </Box>
        </Box>
        <Box className={classes.gridItem}>
          <Box className={classes.inputContainer}>
            <label className={classes.label}>{crimeCategory}</label>
            <Select
              placeholder={dropdownPlaceholder}
              rightSection={<IconChevronDown size='1rem' />}
              className={classes.inputWrapper}
              styles={{ rightSection: { pointerEvents: 'none' } }}
              data={categoryData}
            />
          </Box>
        </Box>
        <Box className={classes.gridItem}>
          <Box className={classes.inputContainer}>
            <label className={classes.label}>{uploadPicture}</label>
            <Box className={classes.uploadPicture} id='upload-picture'>
              <Box className={classes.uploadContainer} id='upload-container'>
                <Dropzone
                  onDrop={(files) => {
                    addFile(files);
                    isFileRejected(false);
                    setTempUploadedFiles(files);
                  }}
                  onReject={(files) => {
                    console.log('rejected files', files);
                    isFileRejected(true);
                  }}
                  maxSize={5 * 1024 ** 2}
                  accept={[
                    MIME_TYPES.png,
                    MIME_TYPES.jpeg,
                    MIME_TYPES.svg,
                    MIME_TYPES.gif,
                    MIME_TYPES.pdf,
                  ]}
                  className={classes.uploadContainer}
                  id='dropzone'
                >
                  <Group
                    position='center'
                    spacing='xl'
                    className={classes.uploadPicture}
                    style={{
                      height: '100%',
                      pointerEvents: 'none',
                    }}
                    id='group'
                  >
                    <Dropzone.Accept>
                      <IconUpload
                        size='3.2rem'
                        stroke={1.5}
                        color={
                          theme.colors[theme.primaryColor][
                            theme.colorScheme === 'dark' ? 4 : 6
                          ]
                        }
                      />
                    </Dropzone.Accept>
                    <Dropzone.Reject>
                      <IconX
                        size='3.2rem'
                        stroke={1.5}
                        color={
                          theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]
                        }
                      />
                    </Dropzone.Reject>
                    <Dropzone.Idle>
                      {isSelected ? (
                        <div className={classes.dropZoneText}>
                          <Text
                            size='14px'
                            ta='center'
                            className={classes.dropZoneText}
                          >
                            {fileRejected === true
                              ? titles.fileRejected
                              : titles.fileAdded}
                          </Text>
                        </div>
                      ) : fileRejected === true ? (
                        <div className={classes.dropZoneText}>
                          <Text
                            size='14px'
                            ta='center'
                            color='red'
                            className={classes.dropZoneText}
                          >
                            {titles.fileRejected}
                          </Text>
                        </div>
                      ) : (
                        <div className={classes.dropZoneText}>
                          <img
                            width='56px'
                            height='56px'
                            src={addIcon}
                            alt='add'
                          />

                          <Text
                            size='14px'
                            ta='center'
                            className={classes.dropZoneText}
                            error={
                              inputError.uploadDocument
                                ? inputError.uploadDocument
                                : false
                            }
                          >
                            {uploadText}
                          </Text>
                        </div>
                      )}
                    </Dropzone.Idle>
                  </Group>
                </Dropzone>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={classes.gridItem}>
          <Box className={classes.inputContainer}>
            <label className={classes.label}>{crimeDescription}</label>
            <Textarea
              placeholder={crimeDescription}
              className={classes.textInput}
            />
          </Box>
        </Box>
      </Box>
      <Box className={classes.gridItem}>
        <Box sx={{ marginTop: 30 }}>
          <label className={classes.label}>{crimeAddress}</label>
          <TextInput
            icon={<BiLocationPlus className={classes.locationIcon} />}
            className={classes.textInput}
            placeholder={crimeAddress}
          />
        </Box>
      </Box>
      <Box className={classes.actionBtns}>
        <Link to='/admin/plan' className={classes.cancel}>
          {cancelBtn}
        </Link>
        <Button
          type='submit'
          className={classes.submitBtn}
          bgColor={theme.colors.secondary[0]}
        >
          {submitBtn}
        </Button>
      </Box>
    </Modal>
  );
}

export default SeeSomethingSaySomethingPopup;
