import { createStyles, rem } from '@mantine/core';
// import bgMobile from '../../assets/images/bgMobile.png';

export const useStyles = createStyles((theme) => ({
  wrapperContainer: {
    background: theme.colors.background[2],
    height: 100,
    [theme.fn.smallerThan('xl')]: {
      height: 90,
    },
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '70% !important',
    margin: 'auto',
    alignItems: 'center',
    position: 'relative',
    height: '100%',
    [theme.fn.smallerThan('xl')]: {
      height: '100%',
      width: '80%!important',
    },
    [theme.fn.smallerThan(1400)]: {
      height: 90,
    },
    [theme.fn.smallerThan(1050)]: {
      width: '100%',
      paddingLeft: 20,
      paddingRight: 10,
    },
    [theme.fn.smallerThan('md')]: {
      width: '95% !important',
    },
  },
  mobDiv: {
    [theme.fn.smallerThan(1025)]: {
      width: '30%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      transform: 'translateY(-8px)',
    },
    [theme.fn.smallerThan('sm')]: {
      width: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      transform: 'translateY(-8px)',
    },
  },
  linksAndUser: {
    display: 'flex',
    columnGap: 25,
    alignItems: 'center',
    [theme.fn.smallerThan(1110)]: {
      paddingLeft: 10,
      paddingRight: 10,
      columnGap: 10,
    },
    [theme.fn.smallerThan(1050)]: {
      display: 'none',
    },
  },
  links: {
    display: 'flex',
    columnGap: 30,
    paddingTop: 10,
    [theme.fn.smallerThan(1200)]: {
      columnGap: 20,
    },
  },
  link: {
    'fontFamily': `Regular`,
    'fontSize': 16,
    'textDecoration': 'none',
    'paddingBottom': 7,
    'color': theme.colors.text[1],
    // borderBottom:' 1px solid transparent',
    'transition': '.4s',
    [theme.fn.smallerThan('xl')]: {
      fontSize: 13,
    },
    [theme.fn.smallerThan('md')]: {
      color: theme.colors.text[2],
    },

    ':hover': {
      color: theme.colors.tertiary[0],
    },

    [theme.fn.smallerThan(1267)]: {
      fontSize: 16,
    },
    [theme.fn.smallerThan(913)]: {
      fontSize: 16,
      color: '#ffffff',
    },
    [theme.fn.smallerThan('sm')]: {
      fontsize: 16,
    },
  },
  activeLink: {
    fontFamily: `Semibold`,
    fontSize: 16,
    textDecoration: 'none',
    width: 'fit-content',
    paddingBottom: 5,
    borderBottom: `1px solid ${theme.colors.tertiary[0]}`,
    color: theme.colors.tertiary[0],
    [theme.fn.smallerThan('xl')]: {
      fontSize: 13,
    },
    [theme.fn.smallerThan('1267')]: {
      fontsize: 16,
    },

    [theme.fn.smallerThan('sm')]: {
      fontSize: 16,
    },
  },
  logo: {
    width: 150,
    // marginTop : 10,
    [theme.fn.smallerThan('xl')]: {
      width: 130,
    },
    [theme.fn.smallerThan(1200)]: {
      width: 130,
      marginTop: 10,
    },
  },
  demoUser: {
    width: 30,
    height: 30,
  },
  seeSomethingNav: {
    width: 170,
    cursor: 'pointer',
  },
  menuTarget: {
    'display': 'flex',
    'columnGap': 5,
    'alignItems': 'center',
    // background : "white",
    'justifyContent': 'center',
    'height': 40,
    'borderRadius': 50,
    'padding': 10,
    // marginTop : 10,
    ':hover': {
      cursor: 'pointer',
    },
  },
  demoProfile: {
    display: 'inline-block',
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    backgroundColor: '#1ED095',
    textAlign: 'center',
    lineHeight: '30px', // Center the text vertically
    color: '#fff', // Text color
    fontSize: '16px', // Adjust font size as needed
  },
  downArrow: {
    fontSize: 25,
    color: theme.colors.text[1],
  },

  dropdown: {
    padding: 10,
    borderRadius: 18,
    boxShadow: '0 0 50px lightgray',
    width: '200px !important',
  },
  dropdownContainer: {
    width: '90%',
    display: 'flex',
    flexDirection: 'column',
    // justifyContent : "center",
    alignItems: 'flex-start',
    marginTop: 100,
    background: 'white',
    borderRadius: 28,
    height: 50,
    transition: '.5s',
    overflowY: 'hidden',
  },
  dropdownContainerOpened: {
    height: 150,
  },
  menuItems: {
    width: '100%',
    paddingLeft: 50,
  },
  menuItem: {
    'fontFamily': `Regular, ${theme.fontFamily}`,
    'width': '95%',
    'fontSize': 16,
    'transition': '.4s',
    'color': theme.colors.text[1],
    'paddingTop': 10,
    'paddingBottom': 10,
    ':hover': {
      backgroundColor: 'transparent',
      color: theme.colors.tertiary[0],
    },
  },
  notificationsIcon: {
    'padding': 7,
    'background': 'white',
    'borderRadius': '50%',
    'width': 40,
    'height': 40,
    'marginTop': 10,
    ':hover': {
      cursor: 'pointer',
    },
  },
  //mobile
  hamburgerContainer: {
    backgroundColor: theme.colors.secondary[0],
    cursor: 'pointer',
    borderRadius: '10px',
    width: 45,
    height: 45,
    padding: 12,
    display: 'flex',
    alignItems: 'center',
  },
  hamburger: {
    width: '100%',
    height: '100%',
  },
  mobileNotification: {
    'width': 30,
    'height': 30,
    'marginTop': 7,
    ':hover': {
      cursor: 'pointer',
    },
  },
  mobileNotificationAndHamburger: {
    display: 'none',
    columnGap: 30,
    [theme.fn.smallerThan(1050)]: {
      display: 'flex',
    },
  },
  //opened mobile nav
  openedMobileNav: {
    backgroundColor: theme.colors.secondary[0],
    width: '100%',
    height: 0,
    position: 'fixed',
    left: 0,
    top: 0,
    userSelect: 'none',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    zIndex: 99,
    backgroundSize: 'auto',
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    // backgroundImage:`url(${bgMobile}), linear-gradient(121.51deg, ${theme.colors.secondary[0]} 30.38%, ${theme.colors.secondary[0]} 53.04%, ${theme.colors.secondary[0]} 71.29%)`,
    transition: '.5s',
    overflowY: 'scroll',
    zIndex: 999999,
  },
  openTheNav: {
    height: '100vh',
    width: '100vw',
    paddingBottom: 100,
  },

  mobileLinks: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 30,
    padding: 20,
    width: '95%',
    marginTop: 15,
  },
  mobileLink: {
    color: 'white',
    textDecoration: 'none',
    width: 'fit-content',
    paddingBottom: 5,
    transition: '.5s',
    fontFamily: `Regular , ${theme.fontFamily}`,
  },
  activeMobileLink: {
    textDecoration: 'none',
    width: 'fit-content',
    paddingBottom: 5,
    transition: '.5s',
    fontFamily: `Regular , ${theme.fontFamily}`,
    color: theme.colors.primary[0],
    borderBottom: `2px solid ${theme.colors.primary[0]}`,
  },

  imageAndUsername: {
    display: 'flex',
    columnGap: 10,
    alignItems: 'center',
  },
  userName: {
    fontFamily: `Medium , ${theme.fontFamily}`,
    fontSize: 15,
    color: theme.colors.text[3],
  },
  menuTargetMobile: {
    'width': '100%',
    'display': 'flex',
    'columnGap': 5,
    'alignItems': 'center',
    'justifyContent': 'space-between',
    'height': 50,
    'borderRadius': 0,
    'padding': 10,
    'WebkitTapHighlightColor': 'transparent',
    'transition': '.5s',
    ':hover': {
      cursor: 'pointer',
    },
  },
  dropdownClosed: {
    // borderBottomLeftRadius : 0,
    // borderBottomRightRadius : 0,
  },
  crossIcon: {
    'padding': 12,
    'width': 40,
    'height': 40,
    'background': 'transparent',
    'borderRadius': 50,
    'position': 'absolute',
    'top': 20,
    'left': 25,
    ':hover': {
      cursor: 'pointer',
    },
  },
  btn: {
    background: theme.colors.primary[0],
    width: '60%',
    marginTop: 30,
    fontFamily: `Regular , ${theme.fontFamily}`,
    [theme.fn.smallerThan(500)]: {
      width: '85%',
    },
  },
  invitebutton: {
    'backgroundColor': `${theme.colors.primary[0]}`,
    'fontFamily': `Light , ${theme.fontFamily}`,
    'fontFamily': `SemiBold , ${theme.fontFamily}`,
    'fontSize': 16,
    'letterSpacing': 0.5,
    'width': 120,
    ':hover': {
      backgroundColor: theme.colors.primary[0],
    },
    'height': 43,
    [theme.fn.smallerThan('xs')]: {
      width: rem(270),
      textAlign: 'center',
    },
    [theme.fn.smallerThan(500)]: {
      minWidth: '100%',
    },
  },
  label: {
    color: `${theme.colors.text[1]}`,
    opacity: 0.5,
    paddingBottom: '10px',
    fontSize: '16px',
    fontFamily: 'Regular',
  },
  title: {
    fontFamily: 'Medium',
    color: `${theme.colors.secondary[0]}`,
    fontSize: '26px',
  },
  textInput: {
    'color': 'white',
    'paddingBottom': '0px',
    'paddingLeft': '0px',
    'marginBottom': '0px',
    'input': {
      fontSize: '16px !important',
      fontFamily: 'Medium !important',
      borderRadius: '16px !important',
      minHeight: 56,
      border: '2px solid #E8E9EC',
    },
    'textarea': {
      fontSize: '16px !important',
      fontFamily: 'Medium !important',
      border: '2px solid #E8E9EC',
      minHeight: '14vw',
      borderRadius: '16px !important',
      [theme.fn.smallerThan('xs')]: {
        minHeight: '178px',
      },
    },
    '.mantine-InputWrapper-error': {
      color: '#ED2424',
      fontFamily: 'Regular',
      fontSize: '16px',
    },
  },
  modalText: {
    color: `${theme.colors.text[1]}`,
    opacity: 0.5,
    paddingBottom: '10px',
    fontSize: '16px',
    fontFamily: 'Regular',
    marginTop: 20,
  },
  cancelButton: {
    'color': `${theme.colors.secondary[0]}`,
    'backgroundColor': `transparent !important`,
    'fontFamily': `SemiBold , ${theme.fontFamily}`,
    'fontSize': 16,
    'letterSpacing': 0.5,
    'width': 120,
    ':hover': {
      backgroundColor: `transparent !important`,
    },
    'height': 43,
    [theme.fn.smallerThan('xs')]: {
      width: rem(270),
      // textAlign:'center',
    },
    [theme.fn.smallerThan(500)]: {
      minWidth: '100%',
    },
  },
  errorWrapper: {
    color: '#ED2424',
    fontFamily: 'Regular',
    fontSize: '16px',
  },
  footerWrapper: {
    display: 'flex',
    justifyContent: 'center',
    [theme.fn.smallerThan('xs')]: {
      flexWrap: 'wrap',
      flexDirection: 'column-reverse',
    },
  },
  modalWrapper: {
    marginTop: '45px !important',
  },
  modalWrapperNew: {
    marginTop: 15,
  },
  mainModalWrapper: {},
  dropDownSelect: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 55,
    border: '0.0625rem solid #ced4da',
    borderRadius: 12,
    padding: 10,
    fontSize: '0.875rem',
  },
  notOpen: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 35,
    border: '0.0625rem solid #ced4da',
    borderRadius: 5,
    padding: 10,
    fontSize: '0.875rem',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    borderBottomColor: 'transparent',
  },
  dropDownWrapp: {
    // top:'116px !important',
    border: '0.0625rem solid #ced4da',
    borderRadius: 5,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    minWidth: '100% !important',

    borderTopColor: 'transparent',
    [theme.fn.smallerThan('sm')]: {
      // width:'100% !important'
    },
  },
  uploadImage: {
    border: '0.0625rem solid #ced4da',
    height: 173,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  uploadSign: {},
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 25,
    height: 45,
  },
  headertext: {
    fontSize: 30,
    fontFamily: 'Regular',
    color: theme.colors.secondary[0],
    marginBottom: 30,
    [theme.fn.smallerThan('xl')]: {
      fontSize: 18,
    },
    [theme.fn.smallerThan('md')]: {
      width: 150,
      fontFamily: 'Bold',
    },
  },
  closeIcon: {
    fontSize: 28,
    cursor: 'pointer',
    [theme.fn.smallerThan('xl')]: {
      fontSize: 18,
    },
  },
  selectionComp: {
    section: {
      borderRadius: '8px',
      padding: 30,
      height: 916,
      [theme.fn.smallerThan('md')]: {
        marginLeft: '0px !important',
        transform: 'translateX(-30px) !important',
      },
    },
  },
  textAreaLabel: {
    marginTop: 5,
    label: {
      color: theme.colors.text[3],
      fontFamily: 'Regular',
      marginBottom: 10,
    },
    textarea: {
      'fontFamily': 'Bold',
      'color': theme.colors.secondary[0],
      'borderRadius': 16,
      'height': 258,

      ':focus': {
        border: `2px solid ${theme.colors.outline[3]}`,
        boxShadow: ' 0px 15px 25px rgba(6, 117, 222, 0.08)',
      },
    },
  },
  inputWrapper: {
    marginTop: 15,
    marginBottom: 55,
    label: {
      color: theme.colors.text[3],
      marginBottom: 10,
    },
    input: {
      'fontFamily': 'Bold',
      'color': theme.colors.secondary[0],
      'height': 56,
      'borderRadius': 12,
      ':focus': {
        border: `2px solid ${theme.colors.outline[3]}`,
        boxShadow: ' 0px 15px 25px rgba(6, 117, 222, 0.08)',
      },
    },
  },
  inputLabel: {
    fontSize: 16,
    fontFamily: 'Regular',
    marginTop: 5,
    label: {
      color: theme.colors.text[3],
      marginBottom: 10,
    },
    input: {
      'fontFamily': 'Bold',
      'color': theme.colors.secondary[0],
      'height': 55,
      'borderRadius': 12,
      ':focus': {
        border: `2px solid ${theme.colors.outline[3]}`,
        boxShadow: ' 0px 15px 25px rgba(6, 117, 222, 0.08)',
      },
    },
  },
  buttonGroup: {
    width: '65%',
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    [theme.fn.smallerThan('md')]: {
      width: '100%',
    },
  },
  submit: {
    background: 'linear-gradient(339.49deg, #06B9B9 9.01%, #00E1E1 98.62%)',
    boxShadow: '0px 10px 30px rgba(0, 225, 225, 0.2)',
    width: 200,
    height: 56,
    borderRadius: 8,
    fontFamily: 'Semibold',
    [theme.fn.smallerThan('md')]: {
      width: '40%',
      height: 40,
    },
  },
  cancel: {
    color: theme.colors.secondary[0],
    width: 200,
    height: 56,
  },
  hamLogo: {
    marginRight: '-171px !important',
    marginTop: '10px',
    cursor: 'pointer',
  },
  verticalBar: {
    height: 38,
    width: 1,
    background: theme.colors.secondary[0],
    marginLeft: 8,
    marginRight: 8,
  },
  notificationUserDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    // width:"13%"
  },
  seeSomethingButton: {
    width: 230,
    height: 70,
    background: 'linear-gradient(105.48deg, #3B4771 11.56%, #1D2643 64.71%)',
    boxShadow: '0px 5px 15px rgba(45, 70, 156, 0.3)',
    borderRadius: '12px',
    cursor: 'pointer',
    [theme.fn.smallerThan('xl')]: {
      width: 180,
      height: 55,
    },
  },
  seeSomethingIcon: {
    width: 30,
  },
  textSaysomething: {
    fontSize: 16,
    [theme.fn.smallerThan('xl')]: {
      fontSize: 13,
    },
  },
  crossIconModal: {
    'marginTop': '1vw',
    'zIndex': 1000,
    'padding': 12,
    'width': 40,
    'height': 40,
    'background': 'white',
    'borderRadius': 50,
    'position': 'absolute',
    'top': 20,
    'right': 25,
    ':hover': {
      cursor: 'pointer',
    },
    [theme.fn.smallerThan(500)]: {
      marginTop: '4vw',
    },
  },
}));
